import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const onClick = (event: Event) => {
    if (event.target instanceof HTMLElement && !event.target.closest('.js-dialog-el')) {
        const popupEl = event.target.closest('app-lit-popup');

        if (popupEl) {
            popupEl.close();
        }
    }
};

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<LitPopupElement>('app-lit-popup[data-lit-popup-preset="dialog"]')).forEach(
        (popupEl) => {
            popupEl.addEventListener('click', onClick);
        },
    );
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<LitPopupElement>('app-lit-popup[data-lit-popup-preset="dialog"]')).forEach(
        (popupEl) => {
            popupEl.removeEventListener('click', onClick);
        },
    );
}

export default { init, destroy };
