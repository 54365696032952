import gsap, { Power3, Power4 } from 'gsap';
import CustomEase from 'gsap/CustomEase';
import { lockBodyScroll, unlockBodyScroll } from '../../utils/dom';
import { withLeadingZero } from '../../utils/strings';

const ANIMATION_SELECTORS = {
    container: '.js-preloader',
    indicatorsContainer: '.js-preloader__indicators',
    indicator1: '.js-preloader__indicator-1',
    indicator2: '.js-preloader__indicator-2',
    indicator3: '.js-preloader__indicator-3',
};

const init = () => {
    // lockBodyScroll();

    return {
        animateTo: (value: number): Promise<void> => {
            return new Promise((resolve) => {
                const timeline = gsap.timeline({
                    onComplete: () => {
                        resolve();
                    },
                });

                const decimal = value === 100 ? '0' : withLeadingZero(value, 3)[1];
                const digit = value % 10;

                timeline.to(ANIMATION_SELECTORS.indicator3, {
                    y: `-${digit === 0 ? 2000 : 1000 * (digit / 10)}%`,
                    duration: 2.5,
                    ease: Power4.easeInOut,
                });

                timeline.to(ANIMATION_SELECTORS.indicator2, {
                    y: `-${100 * (decimal === '0' && value >= 10 ? 10 : Number(decimal))}%`,
                    duration: 2,
                    ease: Power3.easeInOut,
                    delay: -2,
                });

                if (value === 100) {
                    timeline.to(ANIMATION_SELECTORS.indicator1, {
                        y: '-100%',
                        duration: 1.5,
                        delay: -1.5,
                        ease: 'expo.inOut',
                    });
                }

                if (value === 100) {
                    timeline.to(ANIMATION_SELECTORS.indicatorsContainer, {
                        y: '100%',
                        ease: Power4.easeIn,
                        duration: 0.8,
                    });

                    timeline.to(ANIMATION_SELECTORS.container, {
                        y: '100%',
                        ease: CustomEase.create(
                            'custom',
                            'M0,0 C0.272,0 0.372,0.506 0.372,0.506 0.372,0.506 0.458,1 1,1 ',
                        ),
                        duration: 1.2,
                        delay: -0.4,
                        onStart: unlockBodyScroll,
                        onComplete: () => {
                            const preloader = document.querySelector(ANIMATION_SELECTORS.container);
                            preloader?.remove();
                        },
                    });
                }
            });
        },
    };
};

const _module = { init };

export default _module;
