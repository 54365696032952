import './webpack-imports';
import './sentry';
import './custom-elements';
import barba from '@barba/core';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { CustomEase } from 'gsap/CustomEase';
import { applyPolyfills } from './polyfills';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import BaseTransition from './transitions/base';
import './components/cursor';
import { LitPopupElement } from './custom-elements/LitPopupElement/LitPopupElement';
import preloader from './components/preloader';
import navbar from './components/nav-bar';

gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin, CustomEase, ScrollToPlugin);

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.classList.add('js-ready');

    history.scrollRestoration = 'manual';

    applyPolyfills().then(() => {
        const menu = document.querySelector<LitPopupElement>('app-lit-popup.js-menu');

        initBaseView();

        barba.hooks.afterLeave(() => {
            menu?.close();
        });

        preloader.init();
        navbar.init();

        menu?.addEventListener('open', () => {
            document.documentElement.classList.add('menu-opened');
        });

        menu?.addEventListener('close', () => {
            document.documentElement.classList.remove('menu-opened');
        });

        barba.init({
            transitions: [BaseTransition],
            views: [
                lazyView('index-page', () =>
                    import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('zeno-page', () =>
                    import(/* webpackChunkName: "index-page" */ './views/zeno').then((m) => m.default),
                ),
                lazyView('account-page', () =>
                    import(/* webpackChunkName: "account-page" */ './views/account').then((m) => m.default),
                ),
                lazyView('trial-page', () =>
                    import(/* webpackChunkName: "trial-page" */ './views/trial').then((m) => m.default),
                ),
                lazyView('contacts-page', () =>
                    import(/* webpackChunkName: "contacts-page", webpackPrefetch: true */ './views/contacts').then(
                        (m) => m.default,
                    ),
                ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            prevent: ({ el }) => el.tagName === 'A' && !!el.closest('#bx-panel'),
            timeout: 5000,
            debug: NODE_ENV === 'development',
        });
    });
});
